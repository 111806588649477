import { __decorate } from "tslib";
import { Component, Vue, Ref, Watch, } from 'vue-property-decorator';
import { Form, FormItem } from '@/components/shared/CForm/index';
import { ModifyPasswordService } from '@/sevices';
import * as Utils from '@/common/utils';
import { Loading } from '@cloudpivot-hermes/common-components';
let PasswordRule = class PasswordRule extends Vue {
    constructor() {
        super(...arguments);
        this.form = {
            type: [],
            passwordLength: 8,
            defaultPassword: '',
            id: '',
            mandatoryModify: false,
        };
        this.btnLoading = false;
        this.pageLoading = false;
        this.disable = true;
        this.typeOptions = [{
                label: '数字',
                value: 'digit',
            }, {
                label: '小写字母',
                value: 'lowercaseLetter',
            }, {
                label: '大写字母',
                value: 'uppercaseLetter',
            }, {
                label: '英文特殊字符（除空格）',
                value: 'specialCharacter',
            }];
        this.rules = {
            type: [{
                    required: true,
                    message: '至少选择一项',
                    trigger: 'change',
                }],
            passwordLength: [{
                    required: true,
                    message: '请输入密码长度',
                    trigger: 'blur',
                }, {
                    validator: (value) => {
                        if (value < 1) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    },
                    message: '密码长度必须大于等于1',
                    trigger: 'change',
                }, {
                    validator: (value) => {
                        if (value > 32) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    },
                    message: '密码长度最大为32',
                    trigger: 'change',
                }],
            defaultPassword: [{
                    required: true,
                    message: '请输入默认密码',
                    trigger: 'blur',
                }],
        };
    }
    onFormChange() {
        Utils.trim(this.form, 'trimStr');
    }
    itemChange() {
        if (this.form.passwordLength < 1 || this.form.passwordLength > 32) {
            this.disable = true;
        }
        else {
            this.disable = false;
        }
    }
    ;
    async created() {
        this.pageLoading = true;
        await this.getPasswordRule();
        this.pageLoading = false;
    }
    async getPasswordRule() {
        const res = await ModifyPasswordService.getPasswordRule();
        if (res.success) {
            this.form = {
                passwordLength: res.data.passwordLength,
                defaultPassword: res.data.defaultPassword,
                type: [],
                id: res.data.id,
                mandatoryModify: res.data.mandatoryModify,
            };
            this.typeOptions.forEach(item => {
                if (res.data[item.value]) {
                    this.form.type.push(item.value);
                }
            });
        }
    }
    async submit() {
        const isEmpty = this.cform.validate();
        if (!isEmpty) {
            return;
        }
        this.btnLoading = true;
        const params = {
            passwordLength: this.form.passwordLength,
            defaultPassword: this.form.defaultPassword,
            id: this.form.id,
            mandatoryModify: this.form.mandatoryModify,
        };
        this.typeOptions.forEach(item => {
            if (this.form.type.includes(item.value)) {
                params[item.value] = true;
            }
            else {
                params[item.value] = false;
            }
        });
        const res = await ModifyPasswordService.setPasswordRule(params);
        this.btnLoading = false;
        if (res && res.success) {
            this.$message.success('设置成功');
            this.disable = true;
        }
        else {
            this.$message.error(res.errMessage);
        }
    }
};
__decorate([
    Ref()
], PasswordRule.prototype, "cform", void 0);
__decorate([
    Ref()
], PasswordRule.prototype, "inputNumber", void 0);
__decorate([
    Watch('form', { deep: true })
], PasswordRule.prototype, "onFormChange", null);
PasswordRule = __decorate([
    Component({
        name: 'PasswordRule',
        components: {
            CForm: Form,
            CFormItem: FormItem,
            Loading,
        },
    })
], PasswordRule);
export default PasswordRule;
